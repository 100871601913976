<template>
  <b-modal
    id="filter_modal"
    @show="onShow"
    @hide="onClose"
    @hidden="resetDataModal"
    centered
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    scrollable
    size="lg"
  >
    <p class="h3 text-capitalize filterTittle">{{ $t("messages.info.advanced_search") }}</p>
    <hr />

    <div class="filters-container">
      <slot name="main-filters"></slot>
    </div>

    <template #modal-footer="{ close }">
      <b-button variant="primary" size="sm" @click="onClearFiltersClick()" class="text-capitalize" data-cy="clear-filter">
        {{ $t("messages.info.clear_filters") }}
      </b-button>
      <b-button variant="primary" size="sm" @click="close()" data-cy="close-filterModal">
        {{ $t("close") }}
      </b-button>
      <b-button variant="primary" size="sm" @click="onSearchClick(close)" data-cy="search-filter">
        {{ $t("search") }}
        &nbsp;
        <feather-icon icon="SearchIcon" />
      </b-button>
      <slot name="footer-buttons"></slot>
    </template>
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, getCurrentInstance } from "@vue/composition-api";

export default {
  components: {
    BModal,
    vSelect,
  },
  props: {
    buttonUpdate: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const vm = getCurrentInstance().proxy;
    const onShow = () => {};
    const onClose = () => {};
    const resetDataModal = () => {};

    const onSearchClick = (close) => {
      emit("action");
      close();
    };

    const onClearFiltersClick = (close) => {
      emit("clear");
    };

    return {
      onShow,
      onClose,
      resetDataModal,
      onSearchClick,
      onClearFiltersClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.dark-layout {
  .filterTittle {
  color: white;
  }
}
</style>
