<template>
  <div class="d-flex justify-content-end">
    <b-button-group>
      <b-button
      @click="$emit('show-modal', 'filter_modal')"
      class="buttonFilter cursor-pointer"
      data-cy="filter-btn"
    >
    <b-icon-filter-circle-fill
      scale="1"
    />
      <span class="align-middle ml-50 text-capitalize">{{ $t("filters") }}</span>
    </b-button>
    <toggle-clear-filter
      @clear-search-filters="clearSearchFilters"
      :filters-count="filtersCount"
      />
    </b-button-group>
  </div>
</template>
<script>
import ToggleClearFilter from "./ToggleClearFilter.vue";
import { BButton } from "bootstrap-vue";

export default {
  components: {
    ToggleClearFilter,
    BButton,
  },
  props: {
    filtersCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ["show-modal"],

  setup(props, { emit }) {

    const clearSearchFilters = () => {
      emit("clear-search-filters");
    };

    return {
      clearSearchFilters,
    };
  },
}
</script>
