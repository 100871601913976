<template>
  <b-button
    class="buttonFilter"
    @click="clearSearchFilters"
    @mouseover="showTrashIcon = true"
    @mouseleave="showTrashIcon = false"
    v-b-hover="{
      class: 'btn btn-danger clear-filter-btn',
      targets: ['.buttonFilter']
    }"
    :data-cy=" showTrashIcon ? 'clear-filter-btn' : 'count-filter-btn' "
  >
    <span
      class="align-middle"
    >
      <feather-icon
        icon="XIcon"
        v-if="showTrashIcon"
        class="iconPosition"
      />
      {{ showTrashIcon ? "" : filtersCount }}
    </span>
  </b-button>
</template>

<script>
import { ref } from '@vue/composition-api'
export default {
  props: {
    filtersCount: {
      type: Number,
      default: 0,
    },

  },
  setup (props, { emit } ) {
    const showTrashIcon = ref(false)
    const clearSearchFilters = () => {
      emit('clear-search-filters')
    }

    return {
      showTrashIcon,
      clearSearchFilters
    }
  },

}
</script>

<style scoped lang="scss">
.buttonFilter {
  padding: 0.61rem .5rem;
  width: 2.2rem;
}
span {
  position: relative;
  top: .15rem
}
.iconPosition {
  position: relative;
  top: -.1rem;
}
</style>
