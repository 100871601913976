import { ref, computed } from '@vue/composition-api';

export default function useFilterCounter(filters) {

  const filtersCount = computed(() => {
    const count = Object.keys(filters.value).filter((k) => {
      if (Array.isArray(filters.value[k]))
      return filters.value[k].length > 0;

      return filters.value[k] !== null && filters.value[k] !== '';
    });

    return count.length;
  });

  return filtersCount;
}
